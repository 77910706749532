import styled from 'styled-components'
import { white, orange, gray, grayscale } from 'src/styles/colors'
import { device, breakpoints } from 'src/styles/breakpoints'
import { rounded } from 'src/styles/rounded'

export const Wrapper = styled.div`

  h2, h3, h4, h5 {
    font-family: 'Sora';
  }

  .navigation {

    &.drop-menu {

      .inter-logo path {
        fill: ${white} !important;
      }

      .menu {
        border-right: 1px dashed  ${white} !important;
      }

      .icon-menu-hamb span {
        background-color:  ${white} !important;
      }

      @media ${device.desktopLG} {
        .menu-chevron svg {
          fill:  ${white} !important;
        }

        .menu-option,
        .access-button {
          color:  ${white} !important;
        }
      }
    }

    &.landscape {

      button.btn-open-account {
        background: ${orange.extra};
      }
      .access-button {
        border: 1px solid ${orange.base};
        border-radius: ${rounded['3']};
        color:  ${orange.extra} !important;
        display: block;
        font-weight: 600;
        padding-bottom: 5px;
        padding-top: 3px;
      }
    }
  }

  header.navigation {

    @media ${device.desktopLG} {
      .menu {
        border-right: 1px dashed ${gray['600']} !important;
      }
    }

    .icon-menu-hamb span {
      background-color: ${orange.medium};
    }

    .inter-logo {
      &:not(.scroll) {
        path {
          fill: ${orange.extra};
        }
      }
    }

    @media ${device.desktopLG} {
      .menu-option {
        color: #161616;
        font-weight: 600;

        .menu-chevron svg {
          fill: ${orange.extra};
        }
      }
    }
  }
`
export const FiquePorDentro = styled.section`
    padding-top: 96px;

  @media (min-width: ${breakpoints.md}){
      min-height: 642px;
      padding-top: 134px;
    }
    @media (min-width: ${breakpoints.xl}){
      min-height: 700px;
    }


`
export const ContainerLinks = styled.div`
  min-height: 100px;
`
export const Card = styled.div`
  margin: 8px 0px;

  .container-card{
    padding: 14px;
    background: #F5F6FA;
    border: 1px solid #DEDFE4;
    border-radius: 8px;

  }




`
export const Section = styled.section`
  background-color: #F5F6FA;

  .card-carousel {
    background-color: #ffffff;
    height: 276px;
    padding: 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;

    .icon{
      position: absolute;
      left: 24px;
      top: 24px;
    }
  }



`
export const CardSocial = styled.div`
  @media (min-width: ${breakpoints.md}){
    padding: 26px;
  }
  padding: 19px;

  a{
    display: block;
    text-align: right;
  }


`
export const SocialSection = styled.section`
    @media (min-width: ${breakpoints.md}){
      min-height: 559px;
    }
    @media (min-width: ${breakpoints.xl}){
      min-height: 636px;
    }

`
