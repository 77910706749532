import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon'
import { CardSocial } from './style'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

type CardsInformationProps = {
  icon: string;
  socialMedia: string;
  description: string;
  link: string;
  descriptionLink?: string | null;
  sendDatalayerEvent: Function;
}

const SocialMediaCard = ({ icon, socialMedia, description, link, descriptionLink, sendDatalayerEvent }: CardsInformationProps) => {
  return (
    <CardSocial className='border rounded-4 social-media-card'>
      <div>
        <p className='text-orange--extra fs-16 lh-20 fw-600 font-sora'>
          <OrangeDsIcon className='mr-2' color='#ff7a00' size='MD' icon={icon} />
          <span dangerouslySetInnerHTML={{ __html: socialMedia }} />
        </p>
      </div>
      <div className='description d-md-flex align-items-center justify-content-between'>
        <p
          className='text-grayscale--500 fs-16 lh-20 fw-600 limited-p'
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <a
          href={link} target='_blank'
          rel='noreferrer'
          className='fs-14 text-orange--extra'
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_03',
              section_name: 'Fique por dentro das nossas novidades',
              element_action: 'click button',
              element_name: socialMedia + ' - ' + descriptionLink,
              redirect_url: link,
            })
          }}
        >
          {
            descriptionLink && (
              <span dangerouslySetInnerHTML={{ __html: descriptionLink }} />
            )
          }
          <Icon
            className='ml-2' color='orange--base'
            width='25' icon='navigation/arrow-right'
            directory='v2'
          />
        </a>
      </div>
    </CardSocial>
  )
}

export default SocialMediaCard
