import React, { useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import { orange } from 'src/styles/colors'
import { animated, useTrail } from 'react-spring'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useScroll from 'src/hooks/window/useScroll'

import Layout from 'src/layouts/BaseLayout'
import SocialMediaCard from './_SocialMediaCard'
import isVisible from 'src/utils/isVisible'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import social from './social.json'
import carrousel from 'src/assets/data/acesso-rapido/carrousel.json'
import data from 'src/assets/data/acesso-rapido/data.json'
import HeroJSON from 'src/assets/data/acesso-rapido/hero.json'
import pageContext from './pageContext.json'

import { Card, ContainerLinks, FiquePorDentro, Wrapper, Section, SocialSection } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type carrouselProps = {
  icon: string;
  title: string;
  description: string;
  link: string;
}

type CardsInformationProps = {
  socialMedia: string;
  description: string;
  descriptionLink?: string | null;
  link: string;
  icon: string;
}
type FiquePorDentroProps ={
    icon: string;
    description: string;
    link: string;
}
const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}
const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

function AcessoRapido () {
  const [ sendDatalayerEvent ] = useDataLayer()

  const scroll = useScroll(300)

  const section1 = useRef<HTMLDivElement>(null)
  const section2 = useRef<HTMLDivElement>(null)
  const section3 = useRef<HTMLDivElement>(null)

  const [ section1Animetion, setSection1 ] = useTrail(2, () => (translateFrom))
  const [ section2Animetion, setSection2 ] = useTrail(5, () => (translateFrom))
  const [ section3Animetion, setSection3 ] = useTrail(8, () => (translateFrom))

  useEffect(() => {
    if (isVisible(section1.current, -0.3)) {
      setSection1((translateTo))
    }
    if (isVisible(section2.current, -0.3)) {
      setSection2((translateTo))
    }
    if (isVisible(section3.current, -0.3)) {
      setSection3((translateTo))
    }
  }, [ scroll ])

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <FiquePorDentro
          className='d-flex align-items-center pb-5'
          ref={section1}
        >
          <div className='container position-relative '>
            <div>
              <div className='text-left text-md-center mx-md-5 '>
                <animated.h1
                  className='fw-600 text-grayscale--500 fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60'
                  style={section1Animetion[0]}
                  dangerouslySetInnerHTML={{ __html: HeroJSON.title }}
                />

                <animated.div
                  className='fw-400 text-grayscale--500 fs-16 lh-19 fs-md-18 lh-md-22'
                  style={section1Animetion[1]}
                  dangerouslySetInnerHTML={{ __html: HeroJSON.description }}
                />
              </div>
              <animated.div style={section1Animetion[2]}>
                <ContainerLinks className='row'>
                  {data.map((item: FiquePorDentroProps, index: number) => (
                    <Card
                      className=' col-12 col-md-6' key={index}
                      onClick={() => {
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          section_name: 'Fique por dentro das novidades do Inter',
                          element_action: 'click button',
                          element_name: item.description,
                          redirect_url: item.link,
                        })
                      }}
                    >
                      <Link to={item.link}>
                        <div className='d-flex container-card align-items-center'>
                          <div className='mr-2'>
                            <OrangeDsIcon size='MD' icon={item.icon} color={orange.extra} />
                          </div>
                          <h3 className='fs-16 lh-20 fw-600 m-0 text-grayscale--500'>
                            {item.description}
                          </h3>
                          <div className='ml-auto'>
                            <OrangeDsIcon size='MD' icon='arrow-right' color={orange.extra} />
                          </div>
                        </div>
                      </Link>
                    </Card>
                  ))}
                </ContainerLinks>
              </animated.div>
            </div>
          </div>
        </FiquePorDentro>

        <Section
          className='py-5'
          ref={section2}
        >
          <div className='container'>
            <div className='row d-flex align-items-center'>
              <div className='col-12 col-lg-4'>
                <animated.h2
                  className='text-grayscale--500 fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 mb-4'
                  style={section2Animetion[0]}
                >
                  <span className='d-inline d-xl-block'>O melhor do </span>
                  <span className='d-inline d-xl-block'>Inter em um só  </span>
                  clique
                </animated.h2>
              </div>
              <animated.div className='col-12 col-lg-8' style={section2Animetion[1]}>
                <DefaultCarousel
                  sm={{
                      items: 1,
                      partialVisibilityGutter: 20,
                  }}
                  md={{
                      items: 2,
                      partialVisibilityGutter: 70,
                  }}
                  lg={{ items: 2 }}
                  xl={{ items: 2 }}
                >
                  { carrousel.map((item: carrouselProps) => (
                    <div className='rounded-3 card-carousel mr-3' key={item.title}>
                      <Link
                        to={item.link}
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_02',
                            section_name: 'O melhor do Inter em um só clique',
                            element_action: 'click button',
                            element_name: item.title,
                            redirect_url: item.link,
                          })
                        }}
                      >
                        <div className='icon'>
                          <OrangeDsIcon size='LG' icon={item.icon} color={orange.extra} />
                        </div>
                        <div>
                          <h3 className='fs-16 lh-20 mb-2'>{item.title}</h3>
                          <p
                            className='fs-16 lh-20 m-0 text-grayscale--500'
                            dangerouslySetInnerHTML={{ __html: item.description }}
                          />
                        </div>
                      </Link>
                    </div>
                    ),
                  )}
                </DefaultCarousel>
              </animated.div>
            </div>
          </div>
        </Section>

        <SocialSection
          className='py-5 d-flex align-items-center'
          ref={section3}
        >
          <div className='container pt-md-3 pb-md-2'>
            <div className='row'>
              <div className='col-12'>
                <animated.h2
                  className='text-md-center text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-32 fs-xl-40 lh-xl-50 mb-3'
                  style={section3Animetion[0]}
                >
                  Fique por dentro das nossas novidades
                </animated.h2>
                <animated.p className='text-grayscale--500 text-md-center font-inter fs-18 lh-22 mb-5' style={section3Animetion[1]}>
                  <span> Siga as redes sociais para acompanhar em primeira mão o que acontece por aqui</span>
                </animated.p>
              </div>
            </div>
            <animated.div className='row social-media-cards' style={section3Animetion[2]}>
              {social.map((item: CardsInformationProps, index: number) => (
                <div key={index} className='col-12 col-lg-6 mb-4'>
                  <SocialMediaCard
                    socialMedia={item.socialMedia}
                    description={item.description}
                    descriptionLink={item.descriptionLink}
                    link={item.link}
                    icon={item.icon}
                    sendDatalayerEvent={sendDatalayerEvent}
                  />
                </div>
              ))}
            </animated.div>
          </div>
        </SocialSection>
      </Layout>
    </Wrapper>
  )
}

export default AcessoRapido
